export enum ApiResultStatus {
    Ok = 1,
    InternalServerError = 2,
    OrderNotFound = 3,
    FileNotFound = 4,
    OrderAlreadyRated = 5
  }

  export enum OrderDeliveryStatus {
    New = 0,
    ToBeDelivered = 1,
    Delivered = 2,
    NoOneOpened = 3,
    ReadyForCollect = 4
  }

  export enum OrderTrackingActionType {
    SiteVisit = 1,
    GoogleMapsClick = 2,
    BannerClick = 3,
    YouTubeVideoWatch = 4,
    OrderRate = 5,
    BannerAcquiredClick = 6,
  }